import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="guide-footer"
export default class extends Controller {
  static targets = ['item'];

  hover(e) {
    if (this.element.dataset.themeColor.length > 0) {
      // e.target.style.backgroundColor = `lighten(${this.element.dataset.themeColor}, 50%)`;
    }
  }
}
